import React from "react";
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout";
import Seo from "@lekoarts/gatsby-theme-minimal-blog/src/components/seo";

    const PageNotFound = () => (
        <Layout>
            <Seo title="Page Not Found" />
            <h1>Page Not Found</h1>
            <p>Oops! You just hit a route that does not exist.</p>
            <p>Please check the URL again.</p>
        </Layout>
    );
    
    export default PageNotFound;